.App {
  text-align: center;
  font-size: 14px;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body {
  font-family: ibm-plex-sans, Helvetica, Arial, "Lucida Grande", sans-serif;
  line-height: normal;
}

th,
td {
  padding: 5px;
}

.red {
  color: red;
}

.blue {
  color: blue;
}

.bold {
  font-weight: bold;
}

.centerTable {
  vertical-align: middle;
}

.block {
  display: block;
}

.rankPoints {
  display: inline-block;
  width: 1em;
}

.unearned {
  color: #aaa;
}

.leftTable {
  vertical-align: middle;
  text-align: left;
}

.TBAButton {
  margin-right: 10px;
  margin-bottom: 10px;
}

.setupPageMenus {
  background-color: rgb(227, 247, 255);
  padding: 10px;
  border-color: blue;
  border-style: solid;
  margin-bottom: 5px;
}

.setupPageFilters {
  background-color: rgb(231, 255, 227);
  padding: 10px;
  border-color: rgb(107, 221, 0);
  border-style: solid;
  margin-bottom: 10px;
}

.switchLabel {
  display: table-cell;
  height: 25px;
  vertical-align: middle;
}

.gatool-button {
  font-size: 20px;
  font-weight: bold;
  padding: 5px;
  margin: 0px;
  vertical-align: middle;
}

.redAlliance {
  border: 2px solid #ccc;
  background-color: #f7b3b4;
}

.blueAlliance {
  border: 2px solid #ccc;
  background-color: #98b4f4;
}

.redAllianceTeam {
  color: #ff0000;
}

.blueAllianceTeam {
  color: #0000ff;
}

.tieAllianceTeam {
  color: #09ba48;
}

.gatool-playbyplay {
  border: 1px solid #ccc;
  text-align: left;
  align-items: center;
}

.redAlliancePlayByPlay {
  border: 1px solid #ccc;
  align-content: center;
  text-align: center;
  background-color: red;
  font-size: x-large;
  font-weight: bold;
  color: white;
  padding: 4px;
}

.blueAlliancePlayByPlay {
  border: 1px solid #ccc;
  align-content: center;
  text-align: center;
  background-color: blue;
  font-size: x-large;
  font-weight: bold;
  color: white;
  padding: 4px;
}

.playByPlayTable {
  display: table;
  width: 100%;
}

.playByPlayTableRow {
  vertical-align: middle;
  text-align: center;
  display: table-cell;
}

.playByPlayTeamName {
  font-size: 36px;
  margin: 0px;
  line-height: 1em;
}

.playByPlayTeamNameStats {
  font-size: 24px;
  margin: 0px;
}

.playByPlayWinLossTie {
  font-size: large;
  font-style: italic;
  align-content: center;
  text-align: center;
}

.playByPlayRobotName {
  font-style: italic;
  color: #f9fe00;
  margin: 5px 0px 5px;
  line-height: 100%;
}

.playByPlayteamNumber {
  font-size: 40px;
  margin: 0px;
}

.playByPlaysayNumber {
  font-size: medium;
  line-height: 110%;
  margin-bottom: 10px;
  margin-top: 10px;
}

.playByPlayOrganization {
  font-size: medium;
  font-style: italic;
  line-height: 100%;
  margin: 0px;
}

.playByPlayCity {
  font-size: medium;
  line-height: 100%;
  padding-top: 10px;
  margin: 0px;
}

.playByPlayAlliance {
  font-size: 20px;
  line-height: 100%;
  margin-top: 5px;
  display: block;
}

.playByPlayChampsAlliance {
  margin: 0px;
  line-height: 100%;
}

.blueAlliance {
  border: 2px solid #ccc;
  background-color: #98b4f4;
}

.announceTeamNumber {
  font-size: x-large;
  color: black;
}

.announceAlliance {
  font-size: 16px;
  font-weight: bold;
}

.announceOrganization {
  font-size: 20px;
  font-weight: bold;
  line-height: 100%;
}

.sponsors {
  line-height: 1;
  font-weight: 900;
}

.awards {
  font-style: italic;
  line-height: 1;
}

.awardHilight {
  font-weight: bold;
  color: green;
}

.gatool-announce {
  border: 1px solid #ccc;
  text-align: left;
  align-items: center;
  line-height: 110%;
}

.ranking {
  align-content: center;
  vertical-align: middle;
  text-align: center;
  font-size: xx-large;
  color: black;
  padding: 0;
}

.rank1 {
  text-align: center;
  color: white;
  background-color: orange;
}

.rank2 {
  text-align: center;
  color: white;
  background-color: green;
}

.rank9 {
  text-align: center;
  color: black;
  background-color: yellow;
}

.rank0 {
  text-align: center;
  color: black;
}

.teamName {
  font-size: x-large;
  color: black;
  line-height: 100%;
}

.champs {
  color: blue;
}

.HOFRed {
  font-style: italic;
  font-weight: bold;
  color: blue;
  line-height: 1;
}

.HOFBlue {
  font-style: italic;
  font-weight: bold;
  color: red;
  line-height: 1;
}

.HOF {
  font-size: 120%;
}

.robotName {
  font-style: italic;
  font-weight: bold;
  color: #ff4000;
}

.narrowFont {
  font-family: ibm-plex-sans-condensed, Arial-Narrow, "Arial Narrow";
  font-style: normal;
}

.staleTeam {
  border-left: 5px solid red !important;
  color: red !important;
}
.modal-footer {
  justify-content: center;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Column definitions for tables */
.col1 {
  width: 8.333%;
}

.col2 {
  width: 16.667%;
}

.col4 {
  width: 33.333%;
}

.col5 {
  width: 41.667%;
}

.col6 {
  width: 50%;
}

.col7 {
  width: 58.333%;
}

.vertical-center {
  min-height: 100%;
  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh;
  /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}

.wltTable {
  border-width: thin;
  background-color: rgba(255, 255, 255, 0.2);
  text-align: center;
  align-content: center;
  margin: auto;
}

.wltCol {
  font-size: medium;
  white-space: nowrap;
}

.scheduleTablered {
  background-color: #ffd7d7 !important;
}

.scheduleTableblue {
  background-color: #ceceff !important;
}

.scheduleTabletie {
  background-color: #d3ffe3 !important;
}

.buttonNoWrap {
  white-space: nowrap;
}

.awardsButton {
  padding: 5px;
}

.allianceAnnounceDialog {
  font-size: 30px;
  align-content: center;
  text-align: center;
}

.gatool-HighScores {
  font-size: 16px;
  font-weight: bold;
}

.gatool-highScores-Table {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  padding: 0px;
  margin: 0px;
  border: 3px solid;
  border-collapse: collapse;
  margin-bottom: 10px;
}
.gatool-worldHighScores {
  border-color: #dca7a7;
  color: #a94442;
  background-color: #f2dede;
}

.gatool-districtHighScores {
  border-color: #bc9b15;
  color: #ad8b03;
  background-color: #fff5ce;
}

.gatool-eventHighScores {
  border-color: #9acfea;
  color: #31708f;
  background-color: #d9edf7;
}

.statsMatchHeader {
  font-weight: bold;
  font-size: 1.1em;
}

.statsMatchName {
  font-weight: bold;
  font-style: italic;
}

.statsScore {
  font-weight: bolder;
  font-size: 1.2em;
}

.alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.teamNumberButton {
  background-color: #ccc;
}

.teamNumberButton:hover {
  background-color: #aaa;
  cursor: pointer;
}

.teamTable {
  line-height: 1.3;
}

.teamTableHighlight {
  background-color: rgb(195, 244, 199) !important;
}

.teamTableButtonHighlight {
  background-color: rgb(48, 171, 0) !important;
  color: white !important;
}

.teamDataNumber {
  font-size: 18px;
  font-weight: bold;
}

.teamDataButtons {
  margin-left: 5px;
  font-size: 11px;
}

.formHighlight {
  outline: green;
  outline-style: solid;
  background: #f2fff1;
}

.formLabel {
  margin-top: 20px;
}

.robotNameCheckbox {
  margin-left: 10px !important;
}

.davidPrice {
  font-size: 400px;
  text-align: center;
  font-family: "alternate-gothic-no-1-d", sans-serif;
  line-height: 85%;
  vertical-align: middle;
}

.davidPriceSmall {
  font-size: 400px;
  text-align: center;
  font-family: "alternate-gothic-no-1-d", sans-serif;
  line-height: 85%;
  vertical-align: middle;
}

.davidPriceSmallLandscape {
  font-size: 250px;
  text-align: center;
  font-family: "alternate-gothic-no-1-d", sans-serif;
  line-height: 85%;
  vertical-align: middle;
}

.davidPriceCaptain {
  position: relative;
  bottom: 10%;
}

.davidPriceQuals {
  font-size: 700px;
  text-align: center;
  font-family: "alternate-gothic-no-1-d", sans-serif;
  line-height: 85%;
  vertical-align: middle;
}

.davidPriceQualsSmall {
  font-size: 600px;
  text-align: center;
  font-family: "alternate-gothic-no-1-d", sans-serif;
  line-height: 85%;
  vertical-align: middle;
}

.davidPriceWarning {
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.davidPriceSuccess {
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.davidPriceDetail {
  font-size: 80px;
  text-align: center;
  font-family: "alternate-gothic-no-1-d", sans-serif;
  line-height: 90%;
}

.davidPriceDetailSmall {
  font-size: 70px;
  text-align: center;
  font-family: "alternate-gothic-no-1-d", sans-serif;
  line-height: 90%;
}

.davidPriceDetailSmallLandscape {
  font-size: 60px;
  text-align: center;
  font-family: "alternate-gothic-no-1-d", sans-serif;
  line-height: 90%;
}

.playoffDetails {
  font-size: x-large;
  font-weight: 900;
}

.RedReplace {
  margin: 5px;
  color: white;
  background-color: red;
  font-weight: bolder;
}

.BlueReplace {
  margin: 5px;
  color: white;
  background-color: blue;
  font-weight: bolder;
}

.promoteBackup {
  border-radius: 5px;
  font-size: 16px;
  font-stretch: condensed;
  font-weight: bold;
  padding: 5px;
  margin: 0px;
  background-color: darkorchid;
  color: white;
  vertical-align: middle;
  align-content: center;
}

.promoteBackup:hover {
  background-color: rgb(81, 25, 133);
}

.backupDialog {
  font-size: 30px;
  align-content: center;
  text-align: center;
}

.navBarText {
  font-size: 12px;
  white-space: nowrap;
}

.hiddenInput {
  width: 0px;
  height: 0px;
  overflow: hidden;
}

.topBorderLine {
  border-top: 2px darkblue solid;
}

.tbd {
  text-align: center;
  font-weight: bold;
  font-size: x-large;
}

.notes {
  font-size: small;
}

.champsQualified {
  font-size: medium;
  font-weight: bolder;
  color: yellow;
}

.champsQualifiedAnnounce {
  font-weight: bolder;
  color: rgba(22, 87, 2, 0.93);
}

.teamTableHeader {
  white-space: nowrap;
}

.flashCards {
  display: flex;
  align-content: center;
  flex-flow: column;
  align-items: center;
}

.teamNotes p {
  margin: 0px;
  line-height: 1em;
}

.teamNotes h1 {
  font-size: large;
  font-weight: bold;
}

.teamNotes h2 {
  font-size: larger;
  font-weight: bold;
}

.quill {
  border-radius: 6px;
}

.ql-container {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.ql-toolbar {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.ql-editor {
  font-size: 1rem;
}

.ql-size-large {
  font-size: larger;
}

.ql-size-huge {
  font-size: x-large;
}

.ql-size-small {
  font-size: x-small;
}

.FlashcardArrayWrapper {
  width: 100% !important;
}

.hideLandscape {
  display: block;
}
.showLandscape {
  display: none;
}

.foulButtons {
  font-size: 14px;
}

.coopertition {
  background-color: rgb(243, 235, 154) !important;
}

@media only screen and (max-width: 820px) and (orientation: portrait) {
  /* For Portrait iPad: */
  .wltCol {
    font-size: small;
  }
  .redAlliancePlayByPlay {
    font-size: large;
  }
  .blueAlliancePlayByPlay {
    font-size: large;
  }
  .playByPlayTeamName {
    font-size: 30px;
  }
  .playByPlayTeamNameStats {
    font-size: 18px;
  }
  .playByPlayWinLossTie {
    font-size: medium;
  }
  .playByPlayteamNumber {
    font-size: 30px;
  }
  .playByPlaysayNumber {
    font-size: medium;
  }
  .playByPlayOrganization {
    font-size: small;
  }
  .playByPlayCity {
    font-size: small;
  }
  .playByPlayAlliance {
    font-size: 16px;
  }
  .foulButtons {
    font-size: 12px;
  }
}

@media only screen and (max-width: 768px) and (orientation: portrait) {
  /* For Portrait iPad mini: */
  .wltCol {
    font-size: x-small;
  }
  .redAlliancePlayByPlay {
    font-size: medium;
  }
  .blueAlliancePlayByPlay {
    font-size: medium;
  }
  .playByPlayTeamName {
    font-size: 24px;
  }
  .playByPlayTeamNameStats {
    font-size: 14px;
  }
  .playByPlayWinLossTie {
    font-size: small;
  }
  .playByPlayteamNumber {
    font-size: 24px;
  }
  .playByPlaysayNumber {
    font-size: small;
  }
  .playByPlayOrganization {
    font-size: small;
  }
  .playByPlayCity {
    font-size: small;
  }
  .playByPlayAlliance {
    font-size: 14px;
  }
  .foulButtons {
    font-size: 12px;
  }
}

@media screen and (max-width: 1194px) and (orientation: landscape) {
  .wltCol {
    font-size: small;
  }
  .playByPlayWinLossTie {
    font-size: small;
  }
  .playByPlayRobotName {
    font-size: medium;
  }
  .playByPlayTeamName {
    font-size: 28px;
  }
  .hideLandscape {
    display: none;
  }
  .showLandscape {
    display: block;
  }
  .foulButtons {
    font-size: 12px;
  }
}
